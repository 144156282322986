export const fr = {
  abort: "Abandonner",
  addadescription: "Ajouter une description...",
  addadmin: "Ajouter admin",
  addanoption: "Ajouter une option",
  addanothergroup: "Ajouter un autre groupe",
  addanothertrainingticket: "Ajouter un autre ticket",
  addextrafield: "Ajouter un champ supplémentaire",
  addimage: "Ajouter une image",
  addlocation: "Ajouter un emplacement",
  addmember: "Ajouter un membre",
  addmembership: "Ajouter une adhésion",
  addnewfederation: "Ajouter une nouvelle fédération",
  addnews: "Ajouter des nouvelles",
  addnewsport: "Ajouter un nouveau sport",
  addparticipatingevent: "Ajouter un événement participant",
  addplace: "Ajouter un lieu",
  addproductforsale: "Ajouter un produit à vendre",
  addrequirement: "Ajouter une exigence",
  address: "Adresse",
  addsubscription: "Ajouter un abonnement",
  addticket: "Ajouter un ticket",
  addtickets: "Ajouter des tickets",
  addtimegroup: " Ajouter un groupe horaire ",
  addtimeslot: "Ajouter plage horaire",
  addtimeslots: "Ajouter plage horaire",
  addtocompetition: "Ajouter à la compétition",
  admin: "Administration",
  admins: "Administrateurs",
  advanced: "Avancé",
  advertisementdetails: "Détails de l'annonce",
  advertisements: "Annonces",
  agerequirementsfailed: "Les conditions d'âge n'ont pas été remplies",
  allergies: "Allergies",
  alleventsfilter: "Tous les événements",
  allevents: "Tous les évènements",
  allowedmemberships: "Adhésions autorisées",
  allpassedevents: "Tous les évènements passés",
  allupcomingevents: "Tous les évènements à venir",
  alreadypurchased: "Déjà acheté",
  amount: "Quantité",
  annualprice: "Prix annuel",
  apppoweredby: "Cette application est alimentée par:",
  aregoingtothisevent: "{numberriders} coureur(s) va a cet évènement",
  areyousure: "Es-tu sûr ?",
  areyousurethatyouwanttocancelmanymemberships: "Êtes-vous sûr de vouloir annuler les abonnements de ({amount}) membres?",
  areyousurethatyouwanttochangemanymemberships: "Êtes-vous sûr de vouloir mettre à jour {amount} abonnements?",
  areyousureyouwanttorefund: "Êtes-vous sûr de vouloir rembourser cette commande?",
  authenticationatorcode: "Code d'authentification",
  back: "Retour",
  backtoapp: "Retour à l’app",
  backtofacility: "Retour à l'établissement",
  backtohome: "Retour à l’accueil",
  backtologin: "Retour à la connexion",
  balance: "Solde",
  basicinformationregister: "Nous demandons ces informations basiques pour faciliter votre identification et pour personnaliser votre expérience.",
  becomeamember: "Devenir membre",
  beginner: "Débutant",
  begins_at: "Commence à",
  begintime: "Heure de début",
  bikeinputlabel: "Moto",
  bloodtype: "Groupe sanguin",
  brightenscreen: "Éclaircir l'écran",
  bsnnumber: "Numéro d’assurance Nationale",
  bulkeditmembers: "Modifier les membres en masse",
  bulkupdatemembersamount: "Mettre à jour en masse ({amount}) les membres",
  buymembership: "Acheter un abonnement",
  buyticket: "Acheter un billet",
  buytickets: "Acheter des billets",
  buytrainingticket: "Acheter un ticket",
  byacceptingthismandate: " En acceptant ce mandat SEPA, vous autorisez Moto Inside et Stripe à débiter automatiquement votre compte bancaire.",
  cameranotsupported: "La caméra n'est pas accessible ou n'est pas prise en charge.",
  camerapermissionswarning: "La caméra n'est pas accessible. Veuillez vérifier les autorisations de l'appareil photo de l'application.",
  cancel: "Annuler",
  canceleventaction: "Annuler l’épreuve",
  canceleventconfirmation: "Êtes-vous sûr de vouloir annuler cet événement. Tous les coureurs seront remboursés.",
  canceleventseries: "Annuler la série d'événements",
  canceleventserieswarning: "Cet événement fait partie d'une série, veuillez sélectionner les événements que vous souhaitez annuler.",
  cancelingeventpleasewait: "Annulation de l'événement, veuillez patienter.",
  cancelingeventseriespleasewait: "Annulation de la série d'événements, veuillez patienter.",
  cancelledeventsfilter: "Événements annulés",
  cancelledevents: "Événements annulés",
  cancelledstatus: "Annulé",
  cancelmembership: "Annuler l’adhésion",
  cancelmemberships: "Annuler les abonnements",
  capacitycheckinlabel: "enregistré",
  categories: "Catégories",
  categoryallevents: "Tous les évènements",
  categorytraining: "Entrainement",
  changeemailaddress: "Changer l'adresse email",
  changeemailaddressaction: "Changer l'adresse email",
  changeinformation: "Modifier les informations",
  changeexpirationdate: "Modifier la date d'expiration",
  changemember: "Changer de membre",
  changesubscription: "Modifier l'abonnement",
  changephoto: "Changer la photo",
  checkedinriders: "Coureurs enregistrés ({number})",
  checkin: "Check-in",
  checkinnewrider: "Enregistrement nouveau pilote",
  checkinsonthisdevice: "Enregistrements sur cet appareil: ",
  checkyouremail: "Nous venons de vous envoyer un E-mail, merci de vérifier votre boite E-mail ainsi que votre boite spam.",
  chooseacategory: "Choisissez une catégorie",
  chooseaprofilepicture: "Choisissez une photo de profil",
  chooseatype: "Choisir un type",
  chooseaweek: "Choisissez une semaine",
  choosefederation: "Choisissez une fédération",
  chooselevel: "Choisissez le niveau",
  choosemembership: "Choisissez l’adhésion",
  choosemembershipyouwanttoaddmembersto: "Choisissez l’adhésion à laquelle vous voulez ajouter des membres.",
  chooseproducts: "Choisir un produit",
  choosesport: "Choisissez le sport",
  choosetrainingticket: "Choisissez ticket",
  choosetype: "Choisir type",
  choosewhen: "Choisissez quand",
  clear: "Effacer",
  clickoncreateacceptterms: " Lorsque vous cliquez sur créer, vous acceptez les conditions générales et de confidentialité.",
  clicks: "Clics",
  clicktoselectmember: "Cliquez ici pour sélectionner un coureur...",
  close: "Fermer",
  club: "Club",
  commission: "Commission",
  commissions: "Commissions",
  competition: "Concours",
  competitionhasnoplannedevents: "Cette compétition n'a pas d'événements prévus",
  competitionhasnoproductsforsale: "Ce concours n'a actuellement aucun produit à vendre",
  competitions : "Concours",
  competitionstatus: "Concours",
  competitiontickets : "Billets concours",
  concept: "Concept",
  confirm: "Confirmer",
  confirmemail: "Conformation E-mail",
  connecttostripe: "Connecter à Stripe",
  continue: "Continuer",
  copylink: "Copier le lien",
  count: "Compter",
  createanaccount: "Créer un compte",
  createclubdealerorshop: "Créez votre club, revendeur ou boutique et faites-en la promotion auprès des coureurs.",
  create: "Créer",
  createcompetition: "Créer un concours",
  createcompetitiondescription: "Est une série d'événements connectés",
  createevent: "Créer un évènement",
  createeventdescription: "Un événement est utilisé lorsque vous souhaitez héberger un ou plusieurs événements sans connexion",
  createfacility: "Créer une installation",
  createnewpost: "Créer un nouveau post",
  createplacesearch: "Créer {place}",
  createtrack: "Créer une piste",
  creditcard: "Carte de crédit",
  customquantity: "Quantité personnalisée",
  dateofbirth: "Date de naissance",
  deletecheckin: "Supprimer l’enregistrement",
  deletedstatus: "Supprimer",
  depositedon: "Déposé le",
  description: "Description",
  descriptionsaved: "Description sauvegardée",
  deselectall: "Désélectionner tout",
  detailsofregistration: "Détails d’inscription",
  disabletfa: "Désactiver l'authentification à deux facteurs",
  disabletfawithpasswordinstructions: "Pour désactiver l'authentification à deux facteurs, veuillez entrer\n" +
    " à la fois votre code d'authentification actuel et également votre mot de passe.",
  disabletfawithemailinstructions: "Pour désactiver l'authentification à deux facteurs par e-mail, veuillez cliquer" +
    " sur le bouton ci-dessous pour envoyer un code permettant de désactiver votre authentification à" +
    " deux facteurs à votre adresse e-mail. Ensuite, entrez votre mot de passe et le code de" +
    " désactivation à deux facteurs dans le champs ci-dessous.",
  doctorinformation: "Information sur le docteur",
  downloadexport: "Télécharger l’exportation",
  driverlicensenumber: "Numéro de permis de conduire",
  dusty: "Poussiéreuse",
  editadmin: "Modifier admin",
  editevent: "Modifier l’événement",
  editfacility: "Modifier l’installation",
  editmembership: "Modifier adhésion",
  editmembershipaction: "Modifier adhésion",
  editoptions: "Modifier les options",
  email: "E-mail",
  emailisnotverifiedinfo: "Votre e-mail n'est pas vérifié, veuillez cliquer sur le lien ci-dessous pour renvoyer l'e-mail de vérification.",
  emailchangeconfirmed: "Votre adresse e-mail a été mise à jour",
  emergencyinformation: "Information d’urgence",
  enable: "Activer",
  enabletfa: "Activer l'authentification à deux facteurs",
  enabletfainstructions: "Pour activer l'authentification à deux facteurs, utilisez une application d'authentification telle que Google Authenticator pour numériser" +
    " le code QR ci-dessus. <br/><br/>Conservez la chaîne de sécurité dans un endroit sûr, au cas où vous auriez besoin de remplacer ou de perdre votre authentificateur. <br/> <br/>" +
    "Après avoir configuré l'authentificateur, entrez le code à 6 chiffres de l'authentificateur dans le champ ci-dessous.<br/><br/>" +
    "Entrez votre mot de passe pour vérification.",
  ends_at: "Termine à",
  endtime: "Heure de fin",
  endtimeconstrained: "L'heure de fin de l'événement a été contrainte à l'heure de début de l'événement mise à jour",
  enduro: "Enduro",
  enteradescription: "Entrez une description",
  enteravalue: "Entrer une valeur",
  enteremailtocontinue: "Entrez votre adresse e-mail pour continuer",
  entermailorusernametoinviteclub: "Entrer un e-mail ou nom d’utilisateur pour inviter des coureurs dans votre club.",
  enteronetimepasswordaction: "Entrez le mot de passe à usage unique",
  enterthenameofthefield: "Entrez le nom du champ",
  enteryourdetailstocontinue: "Remplissez vos coordonnées pour continuer",
  errorratelimited: "Nos serveurs sont occupés en ce moment, veuillez réessayer plus tard.",
  eventalreadygiven: "L’évènement est déjà donné",
  event: "Epreuve",
  eventname: "Nom de l’épreuve",
  events: "Epreuves",
  eventsfilterintervalhalfyear: "Cette demie année",
  eventsfilterintervalmonth: "Ce mois",
  eventsfilterintervaltoday: "Aujourd’hui",
  eventsfilterintervaltomorrow: "Demain",
  eventsfilterintervalweek: "Cette semaine",
  eventsfilterintervalyear: "Cette année",
  eventsincompetition: "Événements dans cette compétition",
  eventtypesselected: "{length} type d’évènements sélectionnés",
  everyweek: "Chaque semaine",
  everything: "Tout",
  exampleticketname: " Par exemple. Circuit jeunesse ou circuit principal ",
  expiredat: "Expiré à",
  expiresat: "Expire à",
  exporteventcheckins: "Exporter les check-ins",
  exporteventordersaction: "Exporter les paiements",
  exporteventregistrationsaction: "Exporter les enregistrements",
  exportready: "L'exportation est prête",
  exporttransfersaction: "Exporter les transferts",
  extrafields: "Champs supplémentaires",
  extrainformation: "Informations supplémentaires",
  facilities: "Installations",
  facilitiesnearby: " Installations à proximité {town}",
  federations: "Fédérations",
  federationsselected: "{length} fédérations sélectionnées",
  fee: "Frais",
  fees: "Frais",
  female: "Femme",
  fieldisrequired: "Champ requis",
  fillinnewpassword: "Complétez votre nouveau mot de passe.",
  filtereventsby: "Filtrer les événements par",
  filters: "Filtres",
  firstaidpresent: "Premiers secours présents?",
  firstname: "Prénom",
  forceupdatetitle: "Mettez à jour l'application",
  forceupdatemessage: "Pour continuer à utiliser cette application, vous devez la mettre à jour. Veuillez vous rendre sur l'App Store ou Google Play Store pour effectuer la mise à jour.",
  forgotpassword: "Password oublié?",
  forwhichlevelmotorcross: "Pour quel niveau de pilote motocross?",
  freetraining: "Entrainement",
  from: "De",
  fullmembership: "Full Adhésion",
  gender: "Sexe",
  grasstrack: "Grass track",
  groomed: "Soignée",
  groups: "Groupes",
  hasehbo: "A la licence de secouriste",
  hasehboinputlabel: "A la licence de secouriste ?",
  heldat: "Maintenu à",
  heldeventsfilter: "Événements tenus",
  heldevents: "Événements organisés",
  hiddencount: "{count} caché",
  hostedby : "Hébergé par",
  hostfacility: "Installation hôte",
  iban: "IBAN",
  ideal: "iDEAL",
  impressions: "Impressions",
  infoabbrevation: "Info",
  info: "Information",
  instock: "En stock",
  interactions: "Interactions",
  islive: "Is live?",
  isonlyformembersquestion: "Est-ce seulement pour les membres?",
  itemisrequiredquestion: "L'article est requis?",
  knowledge: "Connaissance",
  label: "Libellé",
  lastname: "Nom de famille",
  licensedfederationrequired: "License de la fédération requise?",
  licensenumberfederation: "Numéro de licence",
  licenseplatenumber: "Numéro de licence",
  lineitems: "Éléments de campagne",
  linktostripebuy: "Pour vendre des billets, produits ou autres services vous devez vous connecter au serveur de paiement Stripe",
  loadingmore: "Chargement plus...",
  location: "Emplacement",
  login: "Connecter",
  loginas: "Connexion en tant que",
  loginwith: "Connectez-vous avec",
  loginwithadifferentemail: "Connexion avec une autre adresse e-mail",
  logout: "Se déconnecter",
  lostauthenticator: "Je n'ai pas accès à mon authentificateur",
  maintenancemodemessage: "Nous effectuons actuellement une maintenance planifiée sur nos serveurs. Veuillez réessayer d'ouvrir l'application dans quelques minutes.",
  maintenancemodetitle: "Nous serons bientôt de retour !",
  make: "Faire",
  male: "Homme",
  manage: "Manage",
  maxcapacityleaveblankformaxcapacity: "Capacité maximale (laisser vide pour une capacité illimitée)",
  maxcount: "Nombre maximum",
  maximumage: "Âge maximum (inclus)",
  maximumcountunlimited: " Nombre maximum (0 est illimité)",
  maxproductsperorder: "Maximum par commande",
  maxticketsperticket: "Max par vente",
  medicalacknowledgement_html: "" +
    "<strong>Medische verklaring</strong><br/><br/>" +
    "Ik verklaar dat ik in stem en kennis heb genomen van de volgende drie medische punten:<br/><br/>" +
    "1. Ik verklaar dat ik geestelijk en lichamelijk geschikt bent om motorsport te beoefenen.<br/>" +
    "2. Ik verklaar dat ik geen geneesmiddelen, en/of drugs/doping of alcoholische dranken zal gebruiken die de rijvaardigheid in gevaar brengen.<br/>" +
    "3. Ik verklaar akkoord te zijn met vorenstaande medische verklaring.<br/><br/>" +
    "Bij twijfel neem contact op met het Bondskantoor.",
  membershiprequired: "Adhésion requise?",
  memberships: "Adhésions",
  membershipscancelledamount: "{amount} Abonnements annulés",
  membershipsuccessfull: "Votre inscription a été enregistrée avec succès.",
  membershipsupdatedamount: "{amount} Abonnements mis à jour",
  members: "Membres",
  message: "Message",
  minimumage: "Âge minimum",
  mobilenumber: "Numéro de téléphone portable",
  model: "Modèle",
  mondisclaimer: "MON Disclaimer",
  mondisclaimer_html: "" +
    "<strong>Vrijwaringsverklaring (Exoneratieclausule)</strong><br/>" +
    "1. Gevaarlijke sport<br/>" +
    "Motorsport is een gevaarlijke sport met een verhoogd risico op letsel- en zaakschade.<br/><br/>" +
    "2. Aansprakelijkheid MON c.s. uitgesloten<br/>" +
    "Aansprakelijkheid van MON en de aangesloten verenigingen en haar leden, medewerkers en vrijwilligers (hierna: ’MON c.s.’) voor schade als gevolg van (motorsport)ongevallen is uitdrukkelijk uitgesloten, behoudens opzet of bewuste roekeloosheid. U zal MON c.s. niet voor deze schade aansprakelijk stellen.<br/>" +
    "<br/>" +
    "3. Aansprakelijkheidsrisico MON c.s. niet verzekerbaar<br/>" +
    "De reden voor deze uitsluiting van aansprakelijkheid is dat het aansprakelijkheidsrisico van MON c.s. niet verzekerbaar is. Indien MON c.s. succesvol aansprakelijk zou kunnen worden gesteld, zou de georganiseerde beoefening van motorsport en daarmee het voortbestaan van MON en de aangesloten verenigingen onmogelijk worden.<br/>" +
    "<br/>" +
    "4a. PO-dekking (<strong>enkel van toepassing als gekozen is voor WA daglicentie</strong>)<br/>" +
    "Een PO (Persoonlijke Ongevallendekking) dekking is er alléén voor de MON-startbewijshouders en voor rijders met een OTR- bewijs. Met deze WA daglicentie komt u niet in aanmerking voor de genoemde PO-dekking." +
    "<br/><br/>" +
    "4b. Beperkte PO-dekking (<strong>enkel van toepassing als gekozen is voor WA + PO daglicentie</strong>)<br/>" +
    "De standaarddekking van de afgesloten daglicentie bedraagt bij blijvende invaliditeit maximaal € 25.000,- en bij overlijden € 5.000,-." +
    "<br/><br/>" +
    "5. Voorlichtingsfilm<br/>" +
    "Om u te wijzen op de risico’s waarmee motorsport gepaard gaat, heeft MON een voorlichtingsvideo laten maken. In deze korte film wordt onder meer uitgelegd wat u kunt doen om letsel te voorkomen en wat uw verantwoordelijkheden en die van MON zijn. Iedereen die een motorcrosslicentie of -daglicentie aanvraagt, dient kennis te hebben genomen van de inhoud van de video De mooie en mindere kanten van motorcross, die is te raadplegen via de MON-website (www.mon.nl/licentie-aanvragen/)<br/>" +
    "<br/>" +
    "Alles over de verzekering kunt u lezen in onze verzekeringsbrochure. Deze is te vinden op de pagina: https://mon.nl/licentie-aanvragen." +
    "Heeft u nog vragen? Dan kunt u zich richten tot het bondsbureau van MON via info@mon.nl of 0485-315080.",
  mondisclaimeracknowledgement_html: "" +
    "Ik verklaar dat ik kennis hebt genomen van het voorgaande en ik ben bewust van:<br/>" +
    "1. De risico’s van de beoefening van de motorsport.<br/>" +
    "2. De uitsluiting van de aansprakelijkheid van MON c.s.<br/>" +
    "3. De beperkte dekking van deze daglicentie en de mogelijkheid tot het bijverzekeren tegen betaling;",
  motocross: "Motocross",
  motoinsideappname: "MotoInside",
  muddy: "Boueuse",
  multiselect: "Sélection multiple",
  namecontactperson: "Nom de la personne à contacter",
  namefamilydoctor: "Nom du docteur de famille",
  name: "Nom",
  nationality: "Nationalité",
  nettotal: "Total net",
  never: "Jamais",
  newemailaddress: "Nouvelle adresse e-mail",
  newoption: "Nouvelle option",
  newpost: "Nouveau post",
  news: "Nouvelles",
  newstype: "Type de nouvelles",
  newticket: "Nouveau ticket",
  newtimeslot: "Nouvelle plage horaire",
  newverificationmailsendsuccess: "Un nouvel e-mail de vérification a été envoyé à {email} vous avez 1 heure pour vérifier votre compte.",
  next: "Suivant",
  no: "Non",
  noadvertisements: "Aucune publicité n'a été trouvée pour cet établissement. Actuellement, il est uniquement possible d'obtenir des publicités en nous contactant par e-mail: info@motoinside.app",
  nodescription: "Aucune description fournie",
  noeventregistrationsyet: "Aucune inscription à votre événement pour le moment.",
  noeventsfound: "Aucun évènement n’a été trouvé dans votre région. Modifier le rayon avec les filtres.",
  noeventstocheckin: " Il n’y a aucun événement aujourd’hui qui peut être enregistré ",
  nofacilitiesfound: "Il n’y a pas d’installations à proximité. Changez votre rayon.",
  nofacilitymembersfound: "Cette installation n'a pas de membres actifs.",
  nofacilitysubscriptionsfound: "Cette installation n'a aucun abonnement actif.",
  noinformationadded: "Il n’y a pas d’informations sur votre site. Ajoutez des informations afin que les utilisateurs vous joignent facilement.",
  nolineitemsfound: "Aucun élément de ligne trouvé",
  nomembermotoinside: "Il semble que cette personne n’est pas encore utilisateur de Moto Inside. Faites lui un e-mail pour l’inviter.",
  nomemberslabel: "Aucun membre n'a été trouvé",
  nonewsfound: " Aucune nouvelle trouvée. Créer un article ",
  noordersfound: " Vous n’avez pas encore de commandes. Achetez d’abord des billets ou d’autres produits.",
  nopassedeventslabel: "Cette facilité n’a pas d’évènements passés",
  noproductsfound: "Aucun produit trouvé",
  noriderscheckedinyet: "Aucun coureur n’est encore enregistré. Enregistrez les coureurs en les scannant.",
  nosubscriptionsfound: "Aucun abonnement n'a été trouvé.",
  noticketsavailable: "Pas de ticket disponible",
  notmanagingfacilities: " Vous ne gérez aucune installation pour le moment.",
  notracksarelinked: "Il n’y a pas de piste liée à ce club. Créer une nouvelle piste.",
  notransfersfound: "Aucun transfert trouvé",
  notyetinvited: "Pas encore invité",
  noupcomingeventslabel: "Cet établissement n’a pas d’événements à venir ",
  numberofpendingrefunds: "Nombre de remboursements en attente",
  onetimepassword: "Mot de passe à usage unique",
  ok: "Ok",
  onlyfutureevents: "Annuler les événements futurs",
  onlymembers: "Seuls les membres",
  onlythisevent: "Annuler uniquement cet événement",
  openappstore: "Ouvrir l'App Store d'Apple",
  openplaystore: "Ouvrir le Google Play Store",
  openstatus: "Ouvert",
  openstreetmapattribution: "Search data is powered by © OpenStreetMap contributors available under the Open Database License",
  opentime: "Les ventes commencent le",
  opentimeconstrained: "L'heure de début des ventes a été contrainte à l'heure de début de l'événement mise à jour",
  option: "Option",
  optional : "Facultatif",
  orderconfirmation: "Confirmation de commande",
  orderedat: "A l’ordre de",
  orders: "Ordres",
  organizer: "Organisateur",
  organizingfacility: "Choisissez l’installation d’organisation",
  pagenotauthorized: "Vous n’avez pas accès à cette page",
  pagenotfound: "Page non trouvée",
  paidstatus: "Payé",
  partiallyrefundedstatus: "Partiellement remboursé",
  participatingevents: "Événements participants",
  passedeventstitle: "Evènements passés",
  passwordconfirmation: "Confirmation du mot de passe",
  password: "Mot de passe",
  pay: "Payer € {total}",
  paycash: "Payer cash",
  paycashatevent: "Ne vend pas de billets. Payez en espèces lors de l'événement",
  paymentoverview: 'Aperçu du paiement',
  paymentmethod: 'Mode de paiement',
  paymentmethodbancontact: "Bancontact",
  paymentmethodcard: "Carte / Apple Pay / Google Pay",
  paymentmethodideal: "iDeal",
  paymentmethodgiropay: "Giropay",
  paymentnotavailable: "Le paiement n'est pas disponible",
  payments: "Paiements",
  paymentsurcharge: "Coûts de service",
  payouts: "Paiements",
  payticket: "Payé {price}",
  pendingemailchangerequests: "Demandes de modification d'e-mail en attente",
  pendingrefundsamount: "Montant des remboursements en attente",
  personalqrcode: "QR-code personnel",
  peryear: "par an",
  phonecontactperson: "Téléphone de la personne à contacter",
  phonefamilydoctor: "Téléphone du docteur de famille",
  phonenumber: "Numéro de téléphone",
  phone: "Téléphone",
  pickanoption: "Choisissez une option",
  plannedeventsfilter: "Événements planifiés",
  plannedevents: "Événements planifiés",
  popoveractions: "Actions",
  preferredstartnumber: "Numéro de départ préféré",
  prepped: "Préparée",
  price: "Prix",
  privacyacknowledgement_html: "" +
    "<strong>Privacy verklaring</strong><br/><br/>" +
    "Ik verklaar akkoord te zijn met de volgende privacy verklaring:<br/>" +
    "1. Ik geef toestemming om de persoonsgegevens te verwerken binnen de wettelijke termijn die daarvoor geldt.<br/>" +
    "2. Ik geef toestemming voor het gebruik van foto- en beeldmateriaal voor publicatie t.b.v. MON.<br/>" +
    "3. Ik verklaar akkoord te zijn met vorenstaande privacy verklaring.",
  privacypolicy: "En cliquant sur le bouton d’inscription ci-dessous, vous acceptez notre {0}.",
  privacypolicylabel: "politique de confidentialité",
  privacypolicyquickregister: "En cliquant sur le bouton de confirmation ci-dessous, vous acceptez notre {0}.",
  products: "Produits",
  productsforsale: "Produits en vente",
  productvariants: "Variantes de produits",
  productquantityavailable: "Quantité disponible",
  productisrequiredonceforevent: "L'article est requis une fois pour cet événement",
  professional: "Professionnel",
  profile: "Profil",
  profilesaved: "Les informations de profil ont été enregistrées",
  publish: "Publier",
  pulltorefresh: "Tirer pour rafraîchir",
  qrcode: "QR-code",
  quads: "Quads",
  recreational: "Récréatif",
  refreshing: "Rafraichir...",
  refunded: "Remboursé",
  refundedstatus: "Remboursé",
  refundedsuccessfully: "Le remboursement a été effectué avec succès",
  refundinfo: "Les remboursements sont disponibles pour les commandes payées dans les 180 jours suivant l’achat",
  refundingpleasewait: "Remboursement, veuillez patienter.",
  refunds: "Restitutions",
  register: "Enregistrer",
  registeredat: "Enregistré à",
  registrationform: "Formulaire d’inscription",
  registrations: "Enregistrement",
  remove: "Enlever",
  removesubscription: "Supprimer l’abonnement",
  repeatingendsafter: "La répétition se termine après",
  repeatnewpassword: "Répétez / confirmer votre nouveau mot de passe.",
  repeat: "Répéter",
  requestpasswordreset: "Demander la réinitialisation du mot de passe",
  requestpasswordresetlabel: "Remplissez l’e-mail ci-dessous pour réinitialiser votre mot de passe.",
  requestpasswordresetsuccess: "Un e-mail avec un lien de réinitialisation du mot de passe a été envoyé à {email}",
  required: "Obligatoire",
  requirements: "Exigences",
  resale : "Revente",
  resales : "Reventes",
  resendverificationmail: " Renvoyer le mail de vérification ",
  resetpasswordsuccesfull: "Votre mot de passe a été réinitialisé avec succès. Nous vous avons connecté avec de nouvelles informations d’identification.",
  resetyourpassword: "Réinitialisez votre mot de passe",
  ridercheckedin: "Le coureur est enregistré",
  ridernotfoundqrcodeproblem: "Pilote introuvable. Le code QR ne fonctionne pas",
  rough: "Dure",
  rutted: "Défoncée",
  sale: "Vente",
  salenotstarted: "Vente non commencée",
  sales: "Ventes",
  salestartsin: "La vente commence en {duration}",
  seller: "Vendeur",
  save: "Sauvegarder",
  savechanges: "Enregistrer les modifications",
  search: "Chercher",
  searchalocation: "Chercher un emplacement...",
  searchcreate: " Créer {search}...",
  searchforadmins: " Rechercher des administrateurs...",
  searchforplaces: "Chercher un lieu...",
  searchglobalplaceholder: "Recherchez des événements, des installations, des nouvelles, etc.",
  searchinvalidinput: 'Entrée invalide. Seuls les caractères alphanumériques et les espaces sont autorisés.',
  searchresults: "Résultats de la recherche",
  searchrecentsearches: "Recherches récentes",
  searchnorecentsearches: 'Pas de recherches récentes pour le moment, commencez à rechercher et connectez-vous pour voir cela.',
  searchnoresultfound: 'Aucun résultat de recherche trouvé.',
  searchtaball: 'Tout',
  searchtabevents: 'Événements',
  searchtabfacilities: 'Installations',
  searchtabnews: 'Nouvelles',
  searchuser: "Rechercher un utilisateur",
  select: "Sélectionner",
  selectall: "Sélectionner tout",
  selectbelowwhatdata: "Sélectionner ci dessous quelle donnée vous voulez obtenir des personnes participant à cet évènement.",
  selectedmember: "Membre sélectionné",
  selectedtoinvite: "Sélectionner pour inviter",
  selectevents: "Sélectionner des événements",
  selecteventtype: "Créer un événement",
  selecteditemscount: "{count} éléments sélectionnés",
  selecthostfacility: "Sélectionner une installation hôte",
  selecthostfacilityifotherthenorganizer: "Sélectionner un établissement hôte (si autre que l'organisateur)",
  selectionlimitreached: "Vous avez atteint la limite de ({count}) éléments sélectionnables",
  selectoneofyourbikes: "Sélectionnez l’un de vos vélos",
  selectproductsforevent: "Sélectionner des produits pour l'événement",
  selectorganizer: "Selectionner l’organisateur",
  selecttheitemsyouwanttorefund: "Sélectionnez les articles que vous souhaitez rembourser pour chaque événement.",
  sellingtrainingtickets: "Tickets en vente?",
  selectafacilityfirst: "Sélectionnez d'abord une installation",
  selectapaymentmethod: "Choisissez une méthode de paiement",
  sendinvitations: "Envoyer invitations",
  sendtfadisableemail: "Envoie un nouveau code de désactivation à deux facteurs à mon adresse e-mail",
  seperatedintimegroups: "Séparés en groupes chronométrés?",
  servicecosts: "Les frais de service et les frais de dossier ne seront pas remboursés par l'établissement lorsque votre commande est annulée.",
  share: "Partager",
  sharethispost: "Partager cet article",
  shop: "Boutique",
  showall: "Montrer tout",
  showless: "Montrer moins",
  showmore: "Montrer plus",
  sidecars: "Sidecars",
  soldby: "Vendu par {facility_name}",
  soldout: "Complet",
  soldoutstatus: "Épuisé",
  soldtickets: "Billets vendus",
  soldproducts: "Produits vendus",
  sortby: "Trier par",
  sortbyemail: "E-mail",
  sortbyexpiresat: "Date d'expiration",
  sortbyname: "Nom",
  sortbysubscribedat: "Abonné à",
  sortbysubscription: "Nom d'abonnement",
  status: "État",
  stripeverificationmessage: "A des fins de vérifications et afin de pouvoir utiliser toutes les possibilités de l’application vous devez vous connecter à notre serveur de paiement Stripe. Vous serez alors redirigés sur Stripe en cliquant sur le bouton en bas de page.",
  submit: "Soumettre",
  subscription: "Abonnement",
  subscriptionfirstbefore: "Pour pouvoir ajouter des membres vous devez d’abord créer des adhésions",
  subscriptionremovedsuccessfully: "Abonnement supprimé avec succès",
  subscriptions: "Abonnements",
  suitablefor: "Qui convient pour",
  supermoto: "Supermoto",
  switchevent: "Événement Switch",
  text: "Texte",
  tfadisablecode: "Code de désactivation à deux facteurs",
  tfaisdisabled: "L'authentification à deux facteurs est désactivée",
  tfaisdisabledinformation: "L'authentification à deux facteurs est actuellement désactivée. Activez-la pour sécuriser votre compte.",
  tfaisabled: "L'authentification à deux facteurs est activée",
  tfaisabledinformation: "L'authentification à deux facteurs est actuellement activée.",
  thanksforpurchasemessage: "<p>Vous pouvez maintenant trouver votre code QR sous le bouton CHECK-IN (en haut à droite de cette page). <br/>" +
    "Vous avez besoin de ce code QR pour vous enregistrer à l'événement.</p>" +
    "<p>Pour afficher les détails de votre achat, cliquez sur ‘Ordres‘ dans votre profil.</p>" +
    "<p>Des questions? Envoyez-nous un e-mail à {contactmail} ou appelez {contactphone}</p>",
  thanksforpurchasemessage_unverified: "<p>Vous pouvez maintenant trouver votre code QR sous le bouton CHECK-IN (en haut à droite de cette page). <br/>" +
    "Vous avez besoin de ce code QR pour vous enregistrer à l'événement.</p>" +
    "<p>Si vous souhaitez revenir et utiliser votre code QR ultérieurement, veuillez créer un compte. <br/>" +
    "Vous pouvez le faire en vérifiant simplement l'e-mail que vous venez de recevoir de notre part, puis en définissant un mot de passe dans les étapes de suivi.</p>" +
    "<p>Pour afficher les détails de votre achat, cliquez sur ‘Ordres‘ dans votre profil.</p>" +
    "<p>Des questions? Envoyez-nous un e-mail à {contactmail} ou appelez {contactphone}</p>",
  thanksfortopupmessage: "<p>Votre recharge a été reçue. <br/>" +
    "Cliquez sur le bouton ci-dessous pour retourner à l'établissement.</p>" +
    "<p>Ouvrez l'onglet <strong>Rechargements</strong> dans l'établissement pour consulter l'état de votre solde.</p>" +
    "<p>Vous avez des questions ? Envoyez-nous un e-mail à l'adresse {contactmail} ou appelez le {contactphone}</p>",
  thanksregistration: "Merci de votre inscription. Nous vous avons envoyé un e-mail pour confirmer votre inscription, vous avez une heure pour activer votre compte (vérifiez vos messages indésirables)",
  ticketfor: "Ticket pour",
  ticketisrequired: "Le billet est requis",
  ticketisrequiredonceforevent: "Le billet est requis une fois pour cet événement",
  ticketisseperatedingroups: " Le ticket est séparé en {number} groupes)",
  ticketname: "Nom du ticket",
  tickets: "Tickets",
  ticketsforevents: "Billets pour les événements",
  till: "Jusqu’à",
  time: "temps",
  title: "Titre",
  titlepost: "Titre du poste",
  tocheckout: "Caisse",
  tocreate: "Pour créer",
  toemail: "E-mail",
  toexportattendees: "Exporter les participants",
  topay: "Payer",
  topaywithmethod: "Payer par {method}",
  topup: "Recharger",
  topupconfirmation: "Confirmation de la recharge",
  topupdefaultdescription: "Recharge Moto Inside",
  topupinformation: "<p>Approvisionnez votre compte Connect en transférant de l'argent depuis votre banque vers votre compte.</p>" +
    "<p>Pour soutenir le financement de votre compte, les frais du fournisseur Stripe seront facturés, aucuns autres frais ne seront appliqués.</p>",
  topups: "Rechargements",
  torefund: "Rembourser",
  totalcapacity: "Capacité totale (0 est illimité)",
  totalprice: "Prix total de la commande: {price}",
  total: "Total",
  tracks: "Pistes",
  trackstatus: "Etat de la piste",
  trainer: "Entraineur",
  trainerpresent: "Entraineur présent?",
  trainingevents: "Epreuves d’entrainement",
  trainingmembership: "Adhésion entrainement",
  trainingtickets: "Billets",
  transactionfee: "Frais de transaction",
  transfers: "Transferts",
  transferredon: "Transféré le",
  transferdetails: "Détails du transfert",
  transpondernumber: "Numéro transpondeur",
  trial: "Trial",
  twofactorauthentication: "Authentification à deux facteurs",
  typemembership: "Type d’adhésion",
  type: "Type",
  upcomingeventstitle: "Evènements à venir",
  update: "Mise à jour",
  updateinformation: "Mise à jour d’information",
  uploadportrait: "Charger une photo portrait",
  useavailablestock: "Utiliser le stock disponible",
  userhasnotpaid: "L’utilisateur n’a pas encore payé",
  userhaspaid: "L’utilisateur a payé",
  validation_required: "{attribute} est obligatoire.",
  value: "Valeur",
  verificationmailexpired: "L’e-mail de vérification a expiré ou ne fonctionne pas. Demandez un nouveau lien en saisissant votre e-mail ci-dessous.",
  verificationemailreceived: "Vous avez reçu un e-mail de vérification avec un mot de passe à usage unique, veuillez entrer le mot de passe à usage unique ci-dessous",
  verificationemailsent: "Nous avons envoyé un e-mail de vérification avec un mot de passe à usage unique.",
  verificationsuccessful: "Votre e-mail a été vérifié avec succès. Merci de vous être inscrit sur {motoinsideappname}.",
  viewmoredetails: "Voir plus de détails",
  watered: "Arrosée",
  website: "Site internet",
  whatdatadoyouwanttocollect: "Quelle donnée souhaitez vous collecter?",
  whattocheckin: "Choisissez le type d’événement auquel vous souhaitez vous enregistrer",
  whichbike: "Quelle moto",
  whichfederationcollectlabel: "Quelle fédération",
  whichfederation: "Quelle fédération?",
  whichmembershipsarevalid: "Quelles adhésions sont valides?",
  whichsport: "Quel sport",
  withdrawnon: "Retiré le",
  withinkm: " Dans un rayon de {km}km",
  writeapost: "Ecrire une nouvelle...",
  yearofbuild: "Année de fabrication",
  yearsold: "{age} ans",
  yes: "Oui",
  youhavedisabledgeolocation: "Vous avez désactivé votre position/GPS sur votre appareil, veuillez allumer votre GPS pour une meilleure expérience",
  yourdetails: "Vos détails",
  youreventtickets: "Vos billets pour l’évènement",
  yourfederations: "Vos fédérations",
  yoursports: "Vos sports",
}
