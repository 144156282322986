import { gql } from "@apollo/client";
import { FACILITY_FRAGMENT } from '@/graphql/fragments'
import { USER_FRAGMENT } from "@/graphql/fragments";
import {SINGLE_FACILITY_FRAGMENT} from "./fragments";

export const CONNECT_TO_STRIPE = gql`mutation ($code: String!, $state: String!) {
    connectToStripe(code: $code, state: $state) {
        id
        label
    }
}`

export const UPDATE_FACILITY = gql`mutation($id: ID!, $input: FacilityUpdateInput!) {
    updateFacility(id: $id, input: $input) {
        id
        name
        description
    }
}`

export const CREATE_FACILITY = gql`mutation($data: FacilityInput!) {
    createFacility(data: $data) {
        ...facility
    }
}
${FACILITY_FRAGMENT}`

export const CREATE_NEWS = gql`mutation($data: CreateNewsInput!) {
    createNews(data: $data) {
        id
    }
}`

export const CREATE_EVENT = gql`mutation($data: CreateEvent) {
    createEvent(data: $data) {
        id
        title
    }
}`

export const REMOVE_CHECKEDIN_RIDER = gql`mutation($user_id: ID!, $event_id: ID!) {
    removeCheckedInRider(user_id: $user_id, event_id: $event_id)
}`;

export const LOGIN_USER = gql`mutation($data: LoginInput) {
    loginUser(data: $data) {
        access_token
        refresh_token
        user {
            ...user
        }
    }
}
  ${FACILITY_FRAGMENT}
  ${USER_FRAGMENT}
`

export const LOGIN_WITH_REFRESH_TOKEN = gql`mutation($data: LoginWithRefreshTokenInput) {
    loginWithRefreshToken(data: $data) {
        access_token
        refresh_token
        user {
            ...user
        }
    }
}
  ${FACILITY_FRAGMENT}
  ${USER_FRAGMENT}
`

export const REGISTER_USER = gql`mutation($data: RegisterInput) {
    registerUser(data: $data) {
        first_name
    }
}`

export const RESEND_VERIFICATION_MAIL = gql`mutation($email: String) {
    resendVerificationMail(email: $email)
}`

export const UPDATE_PAYMENT_METHOD_FOR_ORDER = gql`mutation($id: ID!, $payment_method: String!) {
    updatePaymentMethodForOrder(id: $id, payment_method: $payment_method) {
        stripe_account
        session_id
        order_id
    }
}`

export const CHECKOUT_TRAINING_TICKETS = gql`mutation($tickets: [BuyTicketInput!]!, $form_submission: SubmissionDataInput!, $additional_values: JsonTwo!, $payment_method: String) {
    buyTickets(tickets: $tickets, form_submission: $form_submission, additional_values: $additional_values, payment_method: $payment_method) {
        ...on Order {
            id
        }
        ...on StripeSession {
            stripe_account
            session_id
            order_id
        }
    }
}`

export const CHECKOUT_EVENT_TICKETS = gql`mutation($event: ID!, $tickets: [BuyTicketInput!]!, $products: [BuyProductInput!]!, $form_submission: SubmissionDataInput!, $additional_values: JsonTwo!, $payment_method: String) {
    buyEventTickets(event: $event, tickets: $tickets, products: $products, form_submission: $form_submission, additional_values: $additional_values, payment_method: $payment_method) {
        ...on Order {
            id
        }
        ...on StripeSession {
            stripe_account
            session_id
            order_id
        }
    }
}`

export const CHECKOUT_COMPETITION = gql`mutation($data: CheckoutCompetitionInput!) {
    checkoutCompetition(data: $data) {
        ...on Order {
            id
        }
        ...on StripeSession {
            stripe_account
            session_id
            order_id
        }
    }
}`

export const CHECKOUT_TRAINING_TICKET = gql`mutation($ticket: ID!, $form_submission: SubmissionDataInput!, $additional_values: JsonTwo) {
    buyTrainingTicket(ticket: $ticket, form_submission: $form_submission, additional_values: $additional_values) {
        ...on Order {
            id
        }
        ...on StripeSession {
            stripe_account
            session_id
        }
    }
}`
export const ADD_ADMIN = gql`mutation($admin: ID!, $facility: ID!) {
    addAdmin(admin: $admin, facility: $facility) {
        first_name
        last_name
    }
}`

export const CREATE_MEMBERSHIP = gql`mutation($data: MemberShipCreateInput) {
    createMembership(data: $data) 
}`



export const SUBSCRIBE_TO_CLUB = gql`mutation($plan_id: String!, $facility_id: ID!) {
    subscribeToClub(plan_id: $plan_id, facility_id: $facility_id) {
        session_id
        stripe_account
    }
}`

export const CHECKIN_RIDER_TO_EVENT = gql`mutation($user_id: ID!, $event_id: ID!) {
    checkInRiderForEvent(user_id: $user_id, event_id: $event_id) {
        user {
            id
        }
        status
    }
}`

export const INVITE_EXISTING_MEMBER = gql`mutation($data: InviteExistingMemberInput!) {
    InviteExistingMembers(data: $data) {
        id
        first_name
        last_name
    }
}`

export const INVITE_NEW_MEMBERS = gql`mutation($data: InviteNewMembersInput!) {
    InviteNewMembers(data: $data) 
}`

export const UPDATE_FACILITY_INFORMATION = gql`mutation($id: ID!, $data: FacilityInformationUpdateInput!) {
    updateFacilityInformation(id: $id, data: $data) {
        ...singleFacility
    }
}
${SINGLE_FACILITY_FRAGMENT}`

export const FORGOT_PASSWORD = gql`mutation($email: String, $tfa_code: String) {
    passwordReset(email: $email, tfa_code: $tfa_code) {
        ...user
    }
}
${USER_FRAGMENT}
${FACILITY_FRAGMENT}
`

export const NEW_PASSWORD = gql`mutation($data: NewPasswordInput!) {
    newPassword(data: $data) {
        ...user
    }
}
${USER_FRAGMENT}
${FACILITY_FRAGMENT}`

export const NEW_PASSWORD_WITH_TOKEN = gql`mutation($data: NewPasswordInput!) {
    newPasswordWithToken(data: $data) {
        access_token
        refresh_token
        user {
            ...user
        }
    }
}
${USER_FRAGMENT}
${FACILITY_FRAGMENT}`

export const CANCEL_EVENT = gql`mutation($event_id: ID!) {
    cancelEvent(event_id: $event_id) {
        id
    }
}`;

export const CANCEL_FUTURE_EVENTS_IN_SERIES = gql`mutation($parent_id: ID!) {
    cancelFutureEventsInSeries(parent_id: $parent_id)
}`;

export const REFUND_RIDER = gql`mutation($attendee_id: ID!, $orderable_ids: [ID!]) {
    refundRider(attendee_id: $attendee_id, orderable_ids: $orderable_ids) {
        id
    }
}`;

export const EXPORT_EVENT_REGISTRATIONS = gql`mutation($event_id: ID!) {
    exportEventRegistrations(event_id: $event_id)
}`;

export const EXPORT_EVENT_ORDERS = gql`mutation($event_id: ID!) {
    exportEventOrders(event_id: $event_id)
}`;

export const EXPORT_FACILITY_TRANSFERS = gql`mutation($facility_id: ID!) {
    exportFacilityTransfers(facility_id: $facility_id)
}`;

export const EXPORT_MULTI_EVENT_ATTENDEES = gql`mutation($facility_id: ID!, $event_ids: [ID!]!) {
    exportMultiEventAttendees(facility_id: $facility_id, event_ids: $event_ids)
}`;

export const REMOVE_ADMIN = gql`mutation($facility_id: ID!, $admin_id: ID!) {
    removeAdmin(facility_id: $facility_id, admin_id: $admin_id)
}`;

export const UPDATE_EVENT = gql`mutation($id: ID!, $data: UpdateEventInput!) {
    updateEvent(id: $id, data: $data) {
        id
    }
}`;

export const EXPORT_EVENT_CHECKINS = gql`mutation($event_id: ID!) {
    exportEventCheckins(event_id: $event_id)
}`;

export const CREATE_SUBSCRIPTION = gql`mutation($facility_id: ID!, $input: CreateSubscriptionInput!) {
    createSubscription(facility_id: $facility_id, input: $input)
}`;

export const BUY_SUBSCRIPTION = gql`mutation($subscription_id: ID!) {
    buySubscription(subscription_id: $subscription_id)
}`

export const ADD_MEMBER = gql`mutation($data: AddMemberInput!, $facility_id: ID!) {
    addMember(data: $data, facility_id: $facility_id)
}`

export const UPDATE_FACILITY_MEMBER = gql`mutation($id: ID!, $data: UpdateFacilityMemberInput!, $facility_id: ID!) {
    updateFacilityMember(id: $id, facility_id: $facility_id, data: $data)
}`

export const UPDATE_FACILITY_MEMBERS = gql`mutation($ids: [ID!]!, $data: UpdateFacilityMemberInput!, $facility_id: ID!) {
    updateFacilityMembers(ids: $ids, facility_id: $facility_id, data: $data)
}`

export const CANCEL_USER_MEMBERSHIP = gql`mutation($user_id: ID!, $facility_id: ID!) {
    cancelUserMembership(user_id: $user_id, facility_id: $facility_id)
}`

export const CANCEL_MEMBERSHIPS = gql`mutation($ids: [ID!]!, $facility_id: ID!) {
    cancelMemberships(ids: $ids, facility_id: $facility_id)
}`

export const REMOVE_SUBSCRIPTION = gql`mutation($id: ID!, $facility_id: ID!) {
    removeSubscription(id: $id, facility_id: $facility_id)
}`

export const REQUEST_EMAIL_CHANGE = gql`mutation($email: String) {
    requestEmailChange(email: $email)
}`

export const CONFIRM_EMAIL_CHANGE = gql`mutation($email: String, $otp: String, $tfa_code: String) {
    confirmEmailChange(email: $email, otp: $otp, tfa_code: $tfa_code)
}`

export const DELETE_EMAIL_CHANGE = gql`mutation($email: String) {
    deleteEmailChange(email: $email)
}`

export const UPDATE_USER = gql`mutation($data: UpdateUserInput) {
    updateUser(data: $data) {
        ...user
    }
}
${USER_FRAGMENT}
${FACILITY_FRAGMENT}`

export const ENABLE_TFA = gql`mutation($password: String, $tfa_code: String) {
    enableTFA(password: $password, tfa_code: $tfa_code)
}`
export const DISABLE_TFA_WITH_PASSWORD = gql`mutation($password: String, $tfa_code: String) {
    disableTFAWithPassword(password: $password, tfa_code: $tfa_code)
}`

export const DISABLE_TFA_WITH_EMAIL = gql`mutation($email: String, $password: String, $tfa_disable_code: String) {
    disableTFAWithEmail(email: $email, password: $password, tfa_disable_code: $tfa_disable_code)
}`

export const SEND_TFA_DISABLE_EMAIL = gql`mutation($email: String) {
    sendTFADisableCode(email: $email)
}`

export const QUICK_REGISTER_USER = gql`mutation($data: QuickRegisterInput!) {
    quickRegisterUser(data: $data) {
        access_token
        refresh_token
        user {
            ...user
        }
    }
}
${USER_FRAGMENT}
${FACILITY_FRAGMENT}`

export const RECORD_AD_EVENT = gql`mutation($ad_uuid: String!, $event_type: AdEventType!) {
    recordAdEvent(ad_uuid: $ad_uuid, event_type: $event_type)
}`;

export const TOP_UP_BALANCE = gql`mutation($facility_id: ID!, $data: TopUpInput!) {
    topUpBalance(facility_id: $facility_id, data: $data) {
        ...on StripeSession {
            stripe_account
            session_id
            order_id
        }
    }
}`

export const CREATE_RECENT_SEARCH_RESULT = gql`
  mutation createRecentSearchResult($searchableId: ID!, $searchableType: SearchTypeEnum!) {
    createRecentSearchResult(id: $searchableId, type: $searchableType)
  }
`;