import { Preferences } from "@capacitor/preferences";

class AuthStorage {

  /**
   * We set both storage providers, this way, we can migrate to the preferencesProvider in a future version
   */
  constructor() {
    this.localStorageProvider = window.localStorage ? window.localStorage : localStorage;
    this.preferencesProvider = Preferences
  }

  async setItem(tokenName, value) {
    this.localStorageProvider?.setItem(tokenName, value)
    await this.preferencesProvider.set({key: tokenName, value});
  }

  async getItem(tokenName) {
    // Only retrieve values from the localStorageProvider
    return this.localStorageProvider?.getItem(tokenName)
  }

  async removeItem(tokenName) {
    this.localStorageProvider?.removeItem(tokenName)
    await this.preferencesProvider.remove({key: tokenName})
  }

  /**
   * Migrates localStorage keys to Preferences
   *
   * @param names
   * @returns {Promise<void>}
   */
  async migrate(names) {
    for (const key of names) {
      const value = this.localStorageProvider?.getItem(key);
      await this.preferencesProvider.set({key, value});
    }
  }

}

export default new AuthStorage();