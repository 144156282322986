export const en = {
    abort: "Abort",
    addadescription: "Add a description...",
    addadmin: "Add admin",
    addanoption: "Add an option",
    addanothergroup: "Add another group",
    addanothertrainingticket: "Add another ticket",
    addextrafield: "Add extra field",
    addimage: "Add image",
    addlocation: "Add location",
    addmember: "Add member",
    addmembership: "Add membership",
    addnewfederation: "Add new federation",
    addnews: "Add news",
    addnewsport: "Nieuwe sport toevoegen",
    addparticipatingevent: "Add a participating event",
    addplace: "Add place",
    addproductforsale: "Add product for sale",
    addrequirement: "Add requirement",
    address: "Address",
    addsubscription: "Add subscription",
    addticket: "Add ticket",
    addtickets: "Add tickets",
    addtimegroup: "Add time group",
    addtimeslot: "Add timeslot",
    addtimeslots: "Add timeslots",
    addtocompetition: "Add to competition",
    admin: "Administrator",
    admins: "Administrators",
    advanced: "Advanced",
    advertisementdetails: "Advertisement details",
    advertisements: "Advertisements",
    agerequirementsfailed: "Age requirements were not met",
    allergies: "Allergies",
    alleventsfilter: "All events",
    allevents: "All events",
    allowedmemberships: "Allowed memberships",
    allpassedevents: "All passed events",
    allupcomingevents: "All upcoming events",
    alreadypurchased: "Already purchased",
    amount: "Amount",
    annualprice: "Annual price",
    apppoweredby: "This App is powered by:",
    areyousure: "Are you sure?",
    areyousurethatyouwanttocancelmanymemberships: "Are you sure that you want to cancel the memberships of ({amount}) members?",
    areyousurethatyouwanttochangemanymemberships: "Are you sure that you want to update {amount} memberships?",
    aregoingtothisevent: "{numberriders} rider(s) are going to this event",
    areyousureyouwanttorefund: "Are you sure that you want to refund? It will refund the following items.",
    authenticatorcode: "Authenticator code",
    back: "Back",
    backtoapp: "Back to app",
    backtofacility: "Back to the facility",
    backtohome: "Back to home",
    backtologin: "Back to login",
    balance: "Balance",
    basicinformationregister: "We ask this basic information for facilities to identify you and to personalize your experience.",
    becomeamember: "Become a member",
    beginner: "Beginner",
    begins_at: "Begins at",
    begintime: "Begin time",
    bikeinputlabel: "Bike",
    bloodtype: "Bloodtype",
    brightenscreen: "Brighten screen",
    bsnnumber: "National Insurance Number",
    bulkeditmembers: "Bulk edit members",
    bulkupdatemembersamount: "Bulk update ({amount}) members",
    buymembership: "Buy membership",
    buyticket: "Buy a ticket",
    buytickets: "Buy tickets",
    buytrainingticket: "Buy ticket",
    byacceptingthismandate: "By accepting this SEPA mandate your authorizing Moto Inside and Stripe to automatically debit your bank account.",
    cameranotsupported: "The camera can not be accessed or is not supported.",
    camerapermissionswarning: "The camera could not be opened. Please check the app`s camera permissions.",
    cancel: "Cancel",
    canceleventaction: "Cancel event",
    canceleventconfirmation: "Are you sure you want to cancel this event. All riders will be refunded.",
    canceleventseries: "Cancel event series",
    canceleventserieswarning: "This event is part of a series, please select which events that you would like to cancel.",
    cancelingeventpleasewait: "Canceling event, please wait.",
    cancelingeventseriespleasewait: "Canceling event series, please wait.",
    cancelledeventsfilter: "Cancelled events",
    cancelledevents: "Cancelled events",
    cancelledstatus: "Cancelled",
    cancelmembership: "Cancel membership",
    cancelmemberships: "Cancel memberships",
    capacitycheckinlabel: "checked-in",
    categories: "Categories",
    categoryallevents: "All events",
    categorytraining: "Training",
    changeemailaddress: "Change email address",
    changeemailaddressaction: "Change email address",
    changeinformation: "Change information",
    changeexpirationdate: "Change expiration date",
    changemember: "Change member",
    changephoto: "Change photo",
    changesubscription: "Change subscription",
    checkedinriders: "Checked in riders ({number})",
    checkin: "Check-in",
    checkinnewrider: "Checkin new rider",
    checkinsonthisdevice: "Check-ins on this device: ",
    checkyouremail: "We have just sent you an E-mail, please check your E-mail inbox and also your spam inbox.",
    chooseacategory: "Choose a category",
    chooseaprofilepicture: "Choose a profile picture",
    chooseatype: "Choose a type",
    chooseaweek: "Choose a week",
    choosefederation: "Choose federation",
    chooselevel: "Choose level",
    choosemembership: "Choose membership",
    choosemembershipyouwanttoaddmembersto: "Choose membership you want to add members to.",
    chooseproducts: "Choose a product",
    choosesport: "Choose sport",
    choosetrainingticket: "Choose ticket",
    choosetype: "Choose type",
    choosewhen: "Choose when",
    clear: "Clear",
    clickoncreateacceptterms: "When you click on create you agree with the general & privacy terms.",
    clicks: "Clicks",
    clicktoselectmember: "Click here to select a rider...",
    close: "Close",
    club: "Club",
    commission: "Commission",
    commissions: "Commissions",
    competition: "Competition",
    competitionhasnoplannedevents: "This competition has no planned events",
    competitionhasnoproductsforsale: "This competition currently has no products for sale",
    competitions: "Competitions",
    competitionstatus: "Competition",
    competitiontickets: "Competition tickets",
    concept: "Concept",
    confirm: "Confirm",
    confirmemail: "E-mail confirmation",
    connecttostripe: "Connect to Stripe",
    continue: "Continue",
    copylink: "Copy link",
    count: "Count",
    createanaccount: "Create an account",
    createclubdealerorshop: "Create your club, dealer or shop and promote it to riders.",
    createcompetition: "Create a competition",
    createcompetitiondescription: "Is a series of connected of events",
    create: "Create",
    createevent: "Create event",
    createeventdescription: "An event is used when you want to host one or more events with no connection",
    createfacility: "Create facility",
    createnewpost: "Create new post",
    createplacesearch: "Create {place}",
    createtrack: "Create track",
    creditcard: "Creditcard",
    customquantity: "Custom quantity",
    dateofbirth: "Date of birth",
    deletecheckin: "Delete checkin",
    deletedstatus: "Deleted",
    depositedon: "Deposited on",
    description: "Description",
    descriptionsaved: "Description saved",
    deselectall: "Deselect all",
    detailsofregistration: "Details of registration",
    disabletfa: "Disable two-factor authentication",
    disabletfawithpasswordinstructions: "To disable two-factor authentication, please enter\n" +
      " both your current authenticator code and also your password.",
    disabletfawithemailinstructions: "To disable two-factor authentication using email, please click on the button below to send a" +
      " code to disable your two-factor authentication to your email address. Next, enter your password and the Two-Factor-Disable-Code" +
      " into the fields below.",
    doctorinformation: "Doctor information",
    downloadexport: "Download export",
    driverlicensenumber: "Driver license number",
    dusty: "Dusty",
    editadmin: "Edit admin",
    editevent: "Edit event",
    editfacility: "Edit facility",
    editmembership: "Edit membership",
    editmembershipaction: "Edit membership",
    editoptions: "Edit options",
    email: "E-mail",
    emailchangeconfirmed: "Your E-mail address has been updated",
    emailisnotverifiedinfo: "Your E-mail is not verified, please click on the link below to re-send the verification E-mail.",
    emergencyinformation: "Emergency information",
    enable: "Enable",
    enabletfa: "Enable two-factor authentication",
    enabletfainstructions: "To enable two-factor authentication, use an authenticator app such as Google Authenticator to scan" +
      " the QR-code above. <br/><br/>Store the security string in a safe place, in case you need to replace, or lose your authenticator. <br/> <br/>" +
      "After configuring the authenticator, enter the 6-digit code from the authenticator into the field below.<br/><br/>" +
      "Enter your password for verification.",
    ends_at: "Ends at",
    endtime: "End time",
    endtimeconstrained: "Event end time was constrained to the updated event start time",
    enduro: "Enduro",
    enteradescription: "Enter a description",
    enteravalue: "Enter a value",
    enteremailtocontinue: "Enter your E-mail address to continue",
    entermailorusernametoinviteclub: "Enter a e-mail or username to invite riders for you club.",
    enteronetimepasswordaction: "Enter one-time password",
    enterthenameofthefield: "Enter the name of the field",
    enteryourdetailstocontinue: "Enter your details to continue",
    errorratelimited: "Our servers are busy at the moment, please try again later.",
    eventalreadygiven: "Event is already given",
    event: "Event",
    eventname: "Event name",
    events: "Events",
    eventsfilterintervalhalfyear: "This half year",
    eventsfilterintervalmonth: "This month",
    eventsfilterintervaltoday: "Today",
    eventsfilterintervaltomorrow: "Tomorrow",
    eventsfilterintervalweek: "This week",
    eventsfilterintervalyear: "This year",
    eventsincompetition: "Events in this competition",
    eventtypesselected: "{length} event types selected",
    everyweek: "Every week",
    everything: "Everything",
    exampleticketname: "E.g. Youth circuit or main circuit",
    expiredat: "Expired at",
    expiresat: "Expires at",
    exporteventcheckins: "Export check-ins",
    exporteventordersaction: "Export payments",
    exporteventregistrationsaction: "Export registrations",
    exportready: "Export is ready",
    exporttransfersaction: "Export transfers",
    extrafields: "Extra fields",
    extrainformation: "Extra information",
    facilities: "Facilities",
    facilitiesnearby: "Facilities nearby {town}",
    federations: "Federations",
    federationsselected: "{length} federations selected",
    fee: "Fee",
    fees: "Fees",
    female: "Female",
    fieldisrequired: "Field is required",
    fillinnewpassword: "Fill in your new password.",
    filtereventsby: "Filter events by",
    filters: "Filters",
    firstaidpresent: "First aid available?",
    firstname: "First name",
    forceupdatetitle: "Update the app",
    forceupdatemessage: "To continue using this app, you'll need to update it. Please visit the App Store or Google Play Store to perform the update.",
    forgotpassword: "Forgot password?",
    forwhichlevelmotorcross: "For which level of motocross rider?",
    freetraining: "Training",
    from: "From",
    fullmembership: "Full membership",
    gender: "Gender",
    grasstrack: "Grasstrack",
    groomed: "Groomed",
    groups: "Groups",
    hasehbo: "Has First Aid license",
    hasehboinputlabel: "Has First Aid license?",
    heldat: "Held at",
    heldeventsfilter: "Held events",
    heldevents: "Held events",
    hiddencount: "{count} hidden",
    hostedby: "Hosted by",
    hostfacility: "Host facility",
    iban: "IBAN",
    ideal: "iDEAL",
    impressions: "Impressions",
    infoabbrevation: "Info",
    info: "Information",
    instock: "In stock",
    interactions: "Interactions",
    islive: "Is live?",
    isonlyformembersquestion: "Is only for members?",
    itemisrequiredquestion: "Item is required?",
    knowledge: "Knowledge",
    label: "Label",
    lastname: "Last name",
    licensedfederationrequired: "License of federation required?",
    licensenumberfederation: "License number",
    licenseplatenumber: "License plate number",
    lineitems: "Line items",
    linktostripebuy: "To sell tickets, products or other services as {facility} you have to connect to our payment provider Stripe",
    loadingmore: "Loading more...",
    location: "Location",
    login: "Login",
    loginas: "Login as",
    loginwith: "Login with",
    loginwithadifferentemail: "Login with a different email address",
    logout: "Logout",
    lostauthenticator: "I do not have access to my authenticator",
    maintenancemodemessage: "We are currently performing some planned maintenance on our servers. Please try opening the app again in a few minutes.",
    maintenancemodetitle: "We will be right back!",
    make: "Make",
    male: "Male",
    manage: "Manage",
    maxcapacityleaveblankformaxcapacity: "Max Capacity (leave blank for maximum capacity)",
    maxcount: "Max count",
    maximumage: "Maximum age (inclusive)",
    maximumcountunlimited: "Maximum count (0 is unlimited)",
    maxproductsperorder: "Maximum per order",
    maxticketsperticket: "Max per order",
    medicalacknowledgement_html: "" +
      "<strong>Medische verklaring</strong><br/><br/>" +
      "Ik verklaar dat ik in stem en kennis heb genomen van de volgende drie medische punten:<br/><br/>" +
      "1. Ik verklaar dat ik geestelijk en lichamelijk geschikt bent om motorsport te beoefenen.<br/>" +
      "2. Ik verklaar dat ik geen geneesmiddelen, en/of drugs/doping of alcoholische dranken zal gebruiken die de rijvaardigheid in gevaar brengen.<br/>" +
      "3. Ik verklaar akkoord te zijn met vorenstaande medische verklaring.<br/><br/>" +
      "Bij twijfel neem contact op met het Bondskantoor.",
    membershiprequired: "Membership required?",
    memberships: "Memberships",
    membershipscancelledamount: "{amount} Memberships cancelled",
    membershipsuccessfull: "Your membership registration is successfully processed.",
    membershipsupdatedamount: "{amount} Memberships updated",
    members: "Members",
    message: "Message",
    minimumage: "Minimum age",
    mobilenumber: "Mobile number",
    model: "Model",
    mondisclaimer: "MON Disclaimer",
    mondisclaimer_html: "" +
      "<strong>Vrijwaringsverklaring (Exoneratieclausule)</strong><br/>" +
      "1. Gevaarlijke sport<br/>" +
      "Motorsport is een gevaarlijke sport met een verhoogd risico op letsel- en zaakschade.<br/><br/>" +
      "2. Aansprakelijkheid MON c.s. uitgesloten<br/>" +
      "Aansprakelijkheid van MON en de aangesloten verenigingen en haar leden, medewerkers en vrijwilligers (hierna: ’MON c.s.’) voor schade als gevolg van (motorsport)ongevallen is uitdrukkelijk uitgesloten, behoudens opzet of bewuste roekeloosheid. U zal MON c.s. niet voor deze schade aansprakelijk stellen.<br/>" +
      "<br/>" +
      "3. Aansprakelijkheidsrisico MON c.s. niet verzekerbaar<br/>" +
      "De reden voor deze uitsluiting van aansprakelijkheid is dat het aansprakelijkheidsrisico van MON c.s. niet verzekerbaar is. Indien MON c.s. succesvol aansprakelijk zou kunnen worden gesteld, zou de georganiseerde beoefening van motorsport en daarmee het voortbestaan van MON en de aangesloten verenigingen onmogelijk worden.<br/>" +
      "<br/>" +
      "4a. PO-dekking (<strong>enkel van toepassing als gekozen is voor WA daglicentie</strong>)<br/>" +
      "Een PO (Persoonlijke Ongevallendekking) dekking is er alléén voor de MON-startbewijshouders en voor rijders met een OTR- bewijs. Met deze WA daglicentie komt u niet in aanmerking voor de genoemde PO-dekking." +
      "<br/><br/>" +
      "4b. Beperkte PO-dekking (<strong>enkel van toepassing als gekozen is voor WA + PO daglicentie</strong>)<br/>" +
      "De standaarddekking van de afgesloten daglicentie bedraagt bij blijvende invaliditeit maximaal € 25.000,- en bij overlijden € 5.000,-." +
      "<br/><br/>" +
      "5. Voorlichtingsfilm<br/>" +
      "Om u te wijzen op de risico’s waarmee motorsport gepaard gaat, heeft MON een voorlichtingsvideo laten maken. In deze korte film wordt onder meer uitgelegd wat u kunt doen om letsel te voorkomen en wat uw verantwoordelijkheden en die van MON zijn. Iedereen die een motorcrosslicentie of -daglicentie aanvraagt, dient kennis te hebben genomen van de inhoud van de video De mooie en mindere kanten van motorcross, die is te raadplegen via de MON-website (www.mon.nl/licentie-aanvragen/)<br/>" +
      "<br/>" +
      "Alles over de verzekering kunt u lezen in onze verzekeringsbrochure. Deze is te vinden op de pagina: https://mon.nl/licentie-aanvragen." +
      "Heeft u nog vragen? Dan kunt u zich richten tot het bondsbureau van MON via info@mon.nl of 0485-315080.",
    mondisclaimeracknowledgement_html: "" +
      "Ik verklaar dat ik kennis hebt genomen van het voorgaande en ik ben bewust van:<br/>" +
      "1. De risico’s van de beoefening van de motorsport.<br/>" +
      "2. De uitsluiting van de aansprakelijkheid van MON c.s.<br/>" +
      "3. De beperkte dekking van deze daglicentie en de mogelijkheid tot het bijverzekeren tegen betaling;",
    motocross: "Motocross",
    motoinsideappname: "MotoInside",
    muddy: "Muddy",
    multiselect: "Multi select",
    namecontactperson: "Contact person name",
    namefamilydoctor: "Family doctor name",
    name: "Name",
    nationality: "Nationality",
    nettotal: "Net total",
    never: "Never",
    newemailaddress: "New E-mail address",
    newoption: "New option",
    newpost: "New post",
    news: "News",
    newstype: "News type",
    newticket: "New ticket",
    newtimeslot: "New timeslot",
    newverificationmailsendsuccess: "A new verification mail has been send to {email} you have 1 hour to verify your account.",
    next: "Next",
    no: "No",
    noadvertisements: "No advertisements found for this facility. Currently it's only possible to get advertisement by contacting us on e-mail: info@motoinside.app",
    nodescription: "No description provided",
    noeventregistrationsyet: "No registrations to your event yet.",
    noeventsfound: "No events could be found in your area. Change the radius with the filters.",
    noeventstocheckin: "There are no events today which can be checked-in",
    nofacilitiesfound: "There are no facilities near your area. Change your radius.",
    nofacilitymembersfound: "This facility does not have any active members.",
    nofacilitysubscriptionsfound: "This facility does not have any subscriptions.",
    noinformationadded: "There isn’t any information added to your facility. Add information and make it easier for users to reach out to you.",
    nomembermotoinside: "It looks like this person is not a user of Moto Inside yet. Fill in a e-mail to invite him.",
    nomemberslabel: "No members are found",
    nonewsfound: "No news found. Create a post",
    nolineitemsfound: "No line items found",
    notransfersfound: "No transfers found",
    noordersfound: "You don’t have orders yet. Buy tickets or other products first.",
    nopassedeventslabel: "This facility doesn’t have any passed events",
    noproductsfound: "No products found",
    noriderscheckedinyet: "No riders checked in yet. Check-in riders by scanning them.",
    nosubscriptionsfound: "No subscriptions were found.",
    noticketsavailable: "No tickets available",
    notmanagingfacilities: "You aren’t managing any facilities at the moment.",
    notracksarelinked: "No tracks are linked to this club. Create a new track.",
    notyetinvited: "Not yet invited",
    noupcomingeventslabel: "This facility doesn’t have any upcoming events",
    numberofpendingrefunds: "Number of refunds pending",
    onetimepassword: "One-time password",
    ok: "Ok",
    onlyfutureevents: "Cancel future events",
    onlymembers: "Only members",
    onlythisevent: "Cancel only this event",
    openappstore: "Open the Apple App store",
    openplaystore: "Open the Google Play store",
    openstatus: "Open",
    openstreetmapattribution: "Search data is powered by © OpenStreetMap contributors available under the Open Database License",
    opentime: "Sales start on",
    opentimeconstrained: "Sales start time was constrained to the updated event start time",
    option: "Option",
    optional: "Optional",
    orderconfirmation: "Order confirmation",
    orderedat: "Ordered at",
    orders: "Orders",
    organizer: "Organizer",
    organizingfacility: "Pick organizing facility",
    pagenotauthorized: "You don’t have access to this page",
    pagenotfound: "Page not found",
    paidstatus: "Paid",
    partiallyrefundedstatus: "Partially Refunded",
    participatingevents: "Participating events",
    passedeventstitle: "Passed events",
    passwordconfirmation: "Password confirmation",
    password: "Password",
    pay: "Pay € {total}",
    paycash: "Pay cash",
    paycashatevent: "Not selling tickets. Pay cash at the event",
    paymentoverview: 'Payment overview',
    paymentmethod: 'Payment method',
    paymentmethodbancontact: "Bancontact",
    paymentmethodcard: "Card / Apple Pay / Google Pay",
    paymentmethodideal: "iDeal",
    paymentmethodgiropay: "Giropay",
    paymentnotavailable: "Payment is not available",
    payments: "Payments",
    paymentsurcharge: "Service costs",
    payouts: "Payouts",
    payticket: "Pay {price}",
    pendingemailchangerequests: "Pending E-mail change requests",
    pendingrefundsamount: "Pending refunds amount",
    personalqrcode: "Personal QR-code",
    peryear: "per year",
    phonecontactperson: "Contact person phone",
    phonefamilydoctor: "Family doctor phone",
    phonenumber: "Phone number",
    phone: "Phone",
    pickanoption: "Pick an option",
    plannedeventsfilter: "Planned events",
    plannedevents: "Planned events",
    popoveractions: "Actions",
    preferredstartnumber: "Preferred start number",
    prepped: "Prepped",
    price: "Price",
    privacyacknowledgement_html: "" +
      "<strong>Privacy verklaring</strong><br/><br/>" +
      "Ik verklaar akkoord te zijn met de volgende privacy verklaring:<br/>" +
      "1. Ik geef toestemming om de persoonsgegevens te verwerken binnen de wettelijke termijn die daarvoor geldt.<br/>" +
      "2. Ik geef toestemming voor het gebruik van foto- en beeldmateriaal voor publicatie t.b.v. MON.<br/>" +
      "3. Ik verklaar akkoord te zijn met vorenstaande privacy verklaring.",
    privacypolicy: "By clicking register button below you’ll be accepting our {0}.",
    privacypolicylabel: "privacy policy",
    privacypolicyquickregister: "By clicking the confirm button below you’ll be accepting our {0}.",
    productvariants: "Product variants",
    productquantityavailable: "Quantity available",
    productisrequiredonceforevent: "Item is required once for this event",
    products: "Products",
    productsforsale: "Products for sale",
    professional: "Professional",
    profile: "Profile",
    profilesaved: "Profile information has been saved",
    publish: "Publish",
    pulltorefresh: "Pull to refresh",
    qrcode: "QR-code",
    quads: "Quads",
    recreational: "Recreational",
    refreshing: "Refreshing...",
    refunded: "Refunded",
    refundedstatus: "Refunded",
    refundedsuccessfully: "Rider refunded successfully",
    refundinfo: "Refunds are only available for paid orders within 180 days of purchase",
    refundingpleasewait: "Refunding, please wait.",
    refunds: "Refunds",
    register: "Register",
    registeredat: "Registered at",
    registrationform: "Registration form",
    registrations: "Registrations",
    remove: "Remove",
    removesubscription: "Remove subscription",
    repeatingendsafter: "Repeating ends after",
    repeatnewpassword: "Repeat / confirm new password.",
    repeat: "Repeat",
    requestpasswordresetlabel: "Fill in e-mail below to reset your password.",
    requestpasswordreset: "Request password reset",
    requestpasswordresetsuccess: "An email with password reset link has been send to {email}",
    required: "Required",
    requirements: "Requirements",
    resale: "Resale",
    resales: "Resales",
    resendverificationmail: "Resend verification mail",
    resetpasswordsuccesfull: "Your password has been reset successfully. We’ve logged you in with new credentials.",
    resetyourpassword: "Reset your password",
    ridercheckedin: "Rider is checked in",
    ridernotfoundqrcodeproblem: "Rider not found. QR-code not working",
    rough: "Rough",
    rutted: "Rutted",
    sale: "Sale",
    salenotstarted: "Sale not started",
    sales: "Sales",
    salestartsin: "Sale starts in {duration}",
    save: "Save",
    savechanges: "Save changes",
    search: "Search",
    searchalocation: "Search a location...",
    searchcreate: "Create {search}...",
    searchforadmins: "Search for admins...",
    searchforplaces: "Search for places...",
    searchglobalplaceholder: 'Search for events, facilities, news etc...',
    searchinvalidinput: 'Invalid input. Only alphanumeric characters and spaces are allowed.',
    searchresults: "Search results",
    searchrecentsearches: "Recent searches",
    searchnorecentsearches: "No recent searches yet, start searching and login to see this.",
    searchnoresultfound: "No results found",
    searchtaball: 'All',
    searchtabevents: 'Events',
    searchtabfacilities: 'Facilities',
    searchtabnews: 'News',
    searchuser: "Search user",
    selectall: "Select all",
    select: "Select",
    selectbelowwhatdata: "Select below what data you want to collect from the people who attend this event.",
    selecteditemscount: "{count} Items selected",
    selectedmember: "Selected member",
    selectedtoinvite: "Selected to invite",
    selectevents: "Select events",
    selecteventtype: "Create an event",
    selecthostfacility: "Select a host facility",
    selecthostfacilityifotherthenorganizer: "Select a host facility (if other then organizer)",
    selectionlimitreached: "You have reached the limit of ({count}) selectable items",
    selectoneofyourbikes: "Select one of your bikes",
    selectorganizer: "Select organizer",
    selectproductsforevent: "Select products for event",
    selecttheitemsyouwanttorefund: "Select the items that you want to refund from each event.",
    seller: "Seller",
    sellingtrainingtickets: "Selling tickets?",
    selectafacilityfirst: "Select a facility first",
    selectapaymentmethod: "Select a payment method",
    sendinvitations: "Send invitations",
    sendtfadisableemail: "Send a new two-factor-disable-code to my E-mail address",
    seperatedintimegroups: "Seperated in time groups?",
    servicecosts: 'Service cost & application fee will not be refunded by the facility when your order is cancelled.',
    share: "Share",
    sharethispost: "Share this post",
    shop: "Shop",
    showall: "Show all",
    showless: "Show less",
    showmore: "Show more",
    sidecars: "Sidecars",
    soldby: "Sold by {facility_name}",
    soldout: "Sold out",
    soldoutstatus: "Sold out",
    soldtickets: "Sold tickets",
    soldproducts: "Sold products",
    sortby: "Sort by",
    sortbyemail: "E-mail",
    sortbyexpiresat: "Expiration date",
    sortbyname: "Name",
    sortbysubscribedat: "Subscribed at",
    sortbysubscription: "Subscription name",
    status: "Status",
    stripeverificationmessage: "For verification purposes and to be able to use all of the features of the app you have to link to our payment provider Stripe. The facility will be created and you’ll be redirected to Stripe by clicking on the button at the bottom.",
    submit: "Submit",
    subscriptionfirstbefore: "To be able to add members you first have to create memberships",
    subscriptionremovedsuccessfully: "Subscription was removed successfully",
    subscriptions: "Subscriptions",
    subscription: "Subscription",
    suitablefor: "Suitable for",
    supermoto: "Supermoto",
    switchevent: "Switch event",
    text: "Text",
    tfadisablecode: "Two-factor disable code",
    tfaisdisabled: "Two factor authentication is disabled",
    tfaisdisabledinformation: "Two factor authentication is currently disabled. Enable it to secure your account.",
    tfaisenabled: "Two factor authentication is enabled",
    tfaisenabledinformation: "Two factor authentication is currently enabled.",
    thanksforpurchasemessage: "<p>You can now find your QR code under the CHECK IN button (top right of this page). <br/>" +
      "You need this QR code to check in at the event.</p>" +
      "<p>To view the details of your purchase, click on ‘Orders‘ in your profile.</p>" +
      "<p>Any questions? Send us an email at {contactmail} or call {contactphone}</p>",
    thanksforpurchasemessage_unverified: "<p>You can now find your QR code under the CHECK IN button (top right of this page). <br/>" +
      "You need this QR code to check in at the event.</p>" +
      "<p>If you want to return and use your QR code at a later stage, then please create an account. <br/>" +
      "You can do this by simply verifying the email you just received from us and then configuring a password in the follow-up steps.</p>" +
      "<p>To view the details of your purchase, click on ‘Orders‘ in your profile.</p>" +
      "<p>Any questions? Send us an email at {contactmail} or call {contactphone}</p>",
    thanksfortopupmessage: "<p>Your Top-up has been received. <br/>" +
      "Click on the button below to return to the facility.</p>" +
      "<p>To view the details of this Top-up, open the <strong>‘Top ups‘</strong> tab in the facility.</p>" +
      "<p>Any questions? Send us an email at {contactmail} or call {contactphone}</p>",
    thanksregistration: "Thank you for signing up. We’ve send you an e-mail to confirm your account, you have one hour to activate your account. (Also check your spam inbox)",
    ticketfor: "Ticket for",
    ticketisrequired: "Ticket is required",
    ticketisrequiredonceforevent: "Ticket is required once for this event",
    ticketisseperatedingroups: "Ticket is seperated in {number} group(s)",
    ticketname: "Ticket name",
    tickets: "Tickets",
    ticketsforevents: "Tickets for events",
    till: "Till",
    time: "time",
    title: "Title",
    titlepost: "Title post",
    tocheckout: "Checkout",
    tocreate: "Create",
    toemail: "E-mail",
    toexportattendees: "Export attendees",
    topay: "Pay",
    topaywithmethod: "Pay with {method}",
    topup: "Top up",
    topupconfirmation: "Top-up confirmation",
    topupdefaultdescription: "Top-up Moto Inside",
    topupinformation: "<p>Top up your connect account by transferring funds from your bank to your account.</p>" +
      "<p>To facilitate the funding of your account, the stripe fee will be charged, but no additional facility fees will" +
      "be deducted from this order.</p>",
    topups: "Top ups",
    torefund: "Refund",
    totalcapacity: "Total capacity (0 is unlimited)",
    totalprice: "Total order price: {price}",
    total: "Total",
    trackstatus: "Track status",
    tracks: "Tracks",
    trainer: "Trainer",
    trainerpresent: "Trainer present?",
    trainingevents: "Training events",
    trainingmembership: "Training membership",
    trainingtickets: "Tickets",
    transactionfee: "Transaction Fee",
    transfers: "Transfers",
    transferredon: "Transferred on",
    transferdetails: "Transfer details",
    transpondernumber: "Transponder number",
    trial: "Trial",
    twofactorauthentication: "Two factor authentication",
    typemembership: "Membership type",
    type: "Type",
    upcomingeventstitle: "Upcoming events",
    update: "Update",
    updateinformation: "Update information",
    uploadportrait: "Upload a portrait picture",
    useavailablestock: "Use available stock",
    userhasnotpaid: "Rider has not paid yet.",
    userhaspaid: "User has paid",
    validation_required: "{attribute} is required.",
    value: "Value",
    verificationmailexpired: "The verification email is expired or not working. Request a new link by entering your e-mail below.",
    verificationemailreceived: "You have received a verification email with a one-time-password, please enter the one-time-password below",
    verificationemailsent: "We have sent out a verification email with a one-time-password.",
    verificationsuccessful: "Your email was successfully verified. Thank you for registering with {motoinsideappname}.",
    viewmoredetails: "View more details",
    watered: "Watered",
    website: "Website",
    whatdatadoyouwanttocollect: "What data do you want to collect?",
    whattocheckin: "Choose which type of event you want to check-in",
    whichbike: "Which bike",
    whichfederationcollectlabel: "Which federation",
    whichfederation: "Which federation?",
    whichmembershipsarevalid: "Which memberships are valid?",
    whichsport: "Which sport",
    withdrawnon: "Withdrawn on",
    withinkm: "Within {km} km",
    writeapost: "Write a post...",
    yearofbuild: "Year of build",
    yearsold: "{age} years old",
    yes: "Yes",
    youhavedisabledgeolocation: "You have disabled your location / GPS on your device, for the best experience turn on your GPS",
    yourdetails: "Your details",
    youreventtickets: "Your event tickets",
    yourfederations: "Your federations",
    yoursports: "Your sports",
};
