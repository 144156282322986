import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { createUploadLink } from 'apollo-upload-client'
import router from "./router";
import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client/cache'
import fragmentTypes from '@/../fragmentTypes.json'
import { hasAuthError } from './utils/hasAuthError';
import { logoutAndRedirect } from './utils/logoutAndRedirect';
import authStorage from "./utils/authStorage";
import { setContext } from "@apollo/client/link/context";
import { Capacitor } from '@capacitor/core';

/**
 * Generates possibleTypes:
 *
 * {
 *   Seller: ['Facility', 'User'],
 *   SubmissionValue: ['SubmissionString'],
 * }
 */
const possibleTypes = fragmentTypes.__schema.types.reduce( (collection, definition) => {
  collection[definition.name] = definition.possibleTypes.map( type => type.name );

  return collection
}, {});

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage items
export const AUTH_TOKEN = 'apollo-token'
export const REFRESH_TOKEN = 'apollo-refresh-token'

// Http endpoint
const httpEndpoint = `${process.env.VUE_APP_API_URL}/graphql`;

const defaultHeaders = {
  'x-client-app': 'moto-inside-app'
}

const authLink = setContext(async (_, { headers }) => {
  // Use your async token function here:
  const token = await authStorage.getItem(AUTH_TOKEN);
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink
  link: authLink,

  httpLinkOptions: {
    headers: defaultHeaders
  },

  // Override default cache
  // cache: myCache

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Call this in the Vue app file
export function createProvider (options = {}) {
  // Create apollo client

  const platform = Capacitor.getPlatform();

  // Migrate token storage if we are not using the localStorage provider
  if (platform !== 'web') {
    authStorage.migrate([AUTH_TOKEN, REFRESH_TOKEN]);
  }

  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
    cache: new InMemoryCache({
      resultCacheMaxSize: Math.pow(2, 16), // (This is the default cache size)
      possibleTypes,
      dataIdFromObject(responseObject) {
        if (responseObject.pivot) {
          return false; // Disable cacheing of any object if a pivot is defined on it
        }
        return defaultDataIdFromObject(responseObject)
      },
      typePolicies: {
        AuthPayload: {
          keyFields: [], // Do not cache
        },
        GeocodeResult: {
          keyFields: [], // Do not cache
        },
        TFASecrets: {
          keyFields: [], // Do not cache
        },
        User: {
          fields: {
            federations: {
              merge: false,
            },
            sports: {
              merge: false,
            }
          }
        },
        Event: {
          fields: {
            tickets: {
              merge: false,
            },
            products_for_sale: {
              merge: false,
            }
          }
        },
        Query: {
          fields: {
            getFacilityMembers: {
              merge: false, // Do not merge, this fixes a warning on facility members that are deleted
            },
            getFacilitySubscriptions: {
              merge: false, // Do not merge, this fixes a warning on facility subscriptions that are deleted
            }
          }
        }
      }
    })
  })
  apolloClient.wsClient = wsClient
  apolloClient.link = createUploadLink({ uri: httpEndpoint })

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    async errorHandler (error) {
      if(hasAuthError(error)) {
        logoutAndRedirect({ name: 'login', params: { nextUrl: router.currentRoute.fullPath }});
      }

      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })

  return { apolloProvider, apolloClient }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  await authStorage.removeItem(AUTH_TOKEN)
  await authStorage.removeItem(REFRESH_TOKEN)

  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}

export const { apolloProvider, apolloClient } = createProvider()
